import React, { useCallback, useEffect } from 'react';
import { Button, Loading, Modal, PdfUploader } from 'components';
import {
  MenuItem,
  Radio as MatRadio,
  RadioGroup as MatRadioGroup,
  FormControlLabel as MatFormControlLabel,
  FormControl as MatFormControl,
  FormLabel as MatFormLabel,
  TextField as MatTextField
} from '@material-ui/core';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import { Pagination } from '@material-ui/lab';
import { useSetState } from 'utils/hooks/useSetState';
import { convertFileSize } from 'utils/convertFileSize';
import { OPTIONS_TYPE, STATUS_TYPE, CONVERT_TYPE, BOOK_FLIP_TYPE } from 'constants/index';
import {
  UiTable,
  UiTableTitle,
  UiTableItem,
  UiTableContent,
  UiTableContentItem,
  UiTextField,
  UiSelect,
  UiVerifyErrorMsg,
  UiDeleteVerifyWrap,
} from 'styles';
import {
  UiLibrary,
  Uioption,
  UisearchOptions,
  UiSearch,
  UiThumbnail,
  UiPagination,
  UiBtn,
  UiFieldset,
  UibuttonBox,
} from './Library.style';
import { useDoc } from 'store/doc';
import defaultImg from 'assets/images/noImg.png';


/**
 * 書籍總覽列表
 */

export const Library = () => {
  const { setAlert } = useAlert();
  const [
    {
      totalDocsCreateDate: {
        data: totalCreateDate,
        total: totalCreateDateTotal,
        isLoading: isTotalDocsCreateDateLoading
      },
      docs,
      searchType,
      upload
    },
    {
      getTotalDocsCreateDate,
      getDocs,
      createInteractiveObject,
      deleteBook,
      docConvert,
      updateBookJsonContent,
      uploadPDF
    }
  ] = useDoc();

  const total = searchType === '' ? totalCreateDateTotal : docs.total;

  const [{
    selected,
    selectedStatus,
    inputValue,
    currentPage,
    modalDeleteState,
    deleteVerifyInput,
    isOpenFormModal,
    modalForm,
    targetBookId,
    isOpenUploadModal,
    uploadParams,
  }, setState] = useSetState({
    selected: OPTIONS_TYPE.Status,
    selectedStatus: [],
    inputValue: '',
    currentPage: 1,
    modalDeleteState: {
      isOpen: false,
      title: '',
      text: '',
      editData: {},
    },
    deleteVerifyInput: {
      inputContent: '',
      isError: false
    },
    isOpenFormModal: false,
    modalForm: {
      LRFlip: BOOK_FLIP_TYPE.LeftToRight,
      productItemId: '',
      interactiveObjectId: '',
    },
    targetBookId: '',
    isOpenUploadModal: false,
    uploadParams: {}
  });


  const fetchDocs = (page = 1) => {
    const removeEmptyObject = obj => {
      let nextObj = {};
      Object.keys(obj).forEach(key => {
        if (obj[key]) {
          nextObj[key] = obj[key];
        }
      });
      return nextObj;
    };

    let params = { page };
    switch (selected) {
      case OPTIONS_TYPE.Status:
        params.status = selectedStatus.length > 0 ? selectedStatus : null;
        break;
      case OPTIONS_TYPE.Name:
      case OPTIONS_TYPE.BookId:
      default:
        params[selected] = inputValue;
        break;
    }
    getDocs(removeEmptyObject(params));
  };

  const handleSearch = () => {
    fetchDocs();
  };

  const handleSelect = useCallback((event) => {
    setState({ selected: event.target.value });
  }, []);

  const handleStatusSelect = useCallback(event => {
    setState({ selectedStatus: event.target.value });
  }, []);

  const handleInputChange = useCallback(event => {
    setState({ inputValue: event.target.value });
  }, []);

  const handlePaginationChange = (event, page) => {
    setState({ currentPage: page });
    fetchDocs(page);
  };

  const handlePreviewBook = useCallback(bookId => {
    window.open(`https://cdn.oneclass.com.tw/uploadoutput/${bookId}`);
  }, []);

  const handleEditInteractiveObject = useCallback((bookId, interactiveObjectId) => {
    window.open(`https://reader-dev.oneclass.com.tw/${bookId}?interactiveObjectId=${interactiveObjectId}`);
  }, []);

  const handleCreateInteractiveObjectId = useCallback((bookId) => {
    createInteractiveObject(bookId);
  }, []);

  const handleDeleteBook = useCallback(bookId => {
    setState({
      modalDeleteState: {
        isOpen: true,
        title: '您確定要刪除書本嗎？',
        text: '刪除後，會將書本從後台移除。若確定要刪除，請在以下欄位中輸入「刪除」並確認',
        editData: { bookId }
      },
    });
  }, []);

  const handleDocConvert = useCallback(bookId => {
    docConvert(bookId);
  }, []);


  const getInitialLsit = useCallback(async (force) => {
    (force || totalCreateDate.length < 1) && await getTotalDocsCreateDate();
  }, []);

  useEffect(() => {
    if (isTotalDocsCreateDateLoading || totalCreateDate.length < 1) return;
    getDocs({ page: 1 });
  }, [isTotalDocsCreateDateLoading]);

  const handleRefresh = useCallback(() => {
    getInitialLsit(true);
  }, []);

  const openUploadModal = state => {
    setState({
      isOpenUploadModal: state
    });
  };

  const getFileHandler = params => {
    setState({ uploadParams: params });
  };

  const uploadSubmitHandler = async () => {
    if (uploadParams.file.length === 0) return;
    const fileName = uploadParams.file[0].name.split('.')[0];
    const regex = new RegExp('^(?!.*?_$)[\u4e00-\u9fa5_a-zA-Z0-9]+$');

    if (!regex.test(fileName)) {
      setAlert(ALERT_MESSAGE.FILENAME_ERROR, 'error');
      return;
    }
    let formData = new FormData();
    const { type, compressImg, file } = uploadParams;
    if (!file) return;
    const params = {
      type,
      compressImg,
      creater: 'naniAdmin'
    };
    formData.append('pdf', file[0]);
    formData.append('process', params.type);
    await uploadPDF(params, formData);
    openUploadModal(false);
  };

  useEffect(() => {
    getInitialLsit();
  }, []);


  const modalDeleteButtons = [
    {
      text: '確認',
      func: async () => {
        if (deleteVerifyInput.inputContent === '刪除') {
          const data = await deleteBook(modalDeleteState.editData.bookId);
          if (data.value.bookId) {
            setState({
              ...modalDeleteState,
              modalDeleteState: { isOpen: false }
            });
          }
        } else {
          setState({
            ...deleteVerifyInput,
            deleteVerifyInput: {
              isError: true
            }
          });
        }
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => {
        setState({
          ...modalDeleteState,
          modalDeleteState: { isOpen: false }
        });
      }
    }
  ];

  // 切換彈窗開關
  const getModalDeleteStateHandler = state => {
    setState({
      ...modalDeleteState,
      modalDeleteState: { isOpen: state }
    });
  };

  const verifyDeleteInput = e => {
    setState({
      ...deleteVerifyInput,
      deleteVerifyInput: {
        inputContent: e.target.value
      }
    });
  };

  const formModalButtons = [
    {
      text: '確認', func: () => {
        targetBookId && updateBookJsonContent(targetBookId, modalForm);
        setState({ isOpenFormModal: false });
      }
    },
    {
      text: '取消', color: 'highlight', func: () => {
        setState({ isOpenFormModal: false });
      }
    }
  ];

  const uploadModalButtons = useCallback(() => {
    return [
      {
        text: '確認',
        func: () => {
          uploadSubmitHandler();
        },
        loading: upload.isLoading
      },
      {
        text: '取消',
        color: 'highlight',
        func: () => {
          openUploadModal(false);
        },
        loading: upload.isLoading
      }
    ];
  }, [upload.isLoading, uploadParams]);


  const handleChangeLRFlip = event => {
    setState({
      modalForm: {
        ...modalForm,
        LRFlip: event.target.value
      }
    });
  };

  const handleEditBookContent = bookId => {
    const target = docs.dataMap[bookId];
    setState({
      isOpenFormModal: true,
      targetBookId: bookId,
      modalForm: {
        LRFlip: target['LRFlip'] || modalForm.LRFlip,
        productItemId: target.productItemId,
        GCPFileName: target.GCPFileName,
        interactiveObjectId: target.interactiveObjectId,
      }
    });
  };

  return (
    <UiLibrary>
      <Uioption>
        <UiSearch>
          <UiSelect
            variant='outlined'
            value={selected}
            onChange={handleSelect}
          >
            <MenuItem value={OPTIONS_TYPE.Status}>檔案狀態</MenuItem>
            <MenuItem value={OPTIONS_TYPE.Name}>檔案名稱</MenuItem>
            <MenuItem value={OPTIONS_TYPE.BookId}>書本 ID</MenuItem>
          </UiSelect>
          <UisearchOptions>
            {selected === OPTIONS_TYPE.Status && (
              <UiSelect
                multiple
                variant='outlined'
                value={selectedStatus}
                onChange={handleStatusSelect}
              >
                {
                  Object.values(STATUS_TYPE).map(status => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))
                }
              </UiSelect>
            )}
            {(selected === OPTIONS_TYPE.Name || selected === OPTIONS_TYPE.BookId) && (
              <UiTextField
                variant='outlined'
                placeholder={
                  selected === OPTIONS_TYPE.Name ? '請輸入完整書本名稱' : '請輸入完整書本 ID'
                }
                onChange={handleInputChange}
              />
            )
            }
          </UisearchOptions>
          <Button onClick={handleSearch}>搜尋</Button>
        </UiSearch>
        <UibuttonBox>
          <Button buttonColor="highlight" onClick={() => openUploadModal(true)}>上傳PDF</Button>
          <Button onClick={handleRefresh}>列表更新</Button>
        </UibuttonBox>
      </Uioption >
      <UiTable>
        <UiTableTitle>
          <UiTableItem>縮圖</UiTableItem>
          <UiTableItem>檔案名稱</UiTableItem>
          <UiTableItem>檔案容量</UiTableItem>
          <UiTableItem>頁數</UiTableItem>
          <UiTableItem>日期</UiTableItem>
          <UiTableItem>書本ID</UiTableItem>
          <UiTableItem>轉檔類型</UiTableItem>
          <UiTableItem>是否壓縮</UiTableItem>
          <UiTableItem>狀態</UiTableItem>
          <UiTableItem>操作</UiTableItem>
        </UiTableTitle>
        <Loading isLoading={docs.isLoading || isTotalDocsCreateDateLoading} />
        <UiTableContent>
          {
            docs.data.filter(item => !item.isDeleted).map(item => (
              <UiTableContentItem key={item.bookId}>
                <UiTableItem>
                  <UiThumbnail
                    onError={(e) => { e.target.onerror = null; e.target.src = defaultImg; }}
                    src={item.thumbnail} alt=""
                  />
                </UiTableItem>
                <UiTableItem>{item.originalName}</UiTableItem>
                <UiTableItem>{convertFileSize(item.size)}</UiTableItem>
                <UiTableItem>{item.numPages}</UiTableItem>
                <UiTableItem>{item.createDate}</UiTableItem>
                <UiTableItem>{item.bookId}</UiTableItem>
                <UiTableItem>{CONVERT_TYPE[item.type]}</UiTableItem>
                <UiTableItem>{item.compressImg ? '是' : '否'}</UiTableItem>
                <UiTableItem>{item.status}</UiTableItem>
                <UiTableItem>
                  <UiBtn>
                    <Button
                      buttonColor="info"
                      onClick={() => handleEditBookContent(item.bookId)}>
                      編輯JSON
                    </Button>
                    {
                      item.interactiveObjectId ? (
                        <Button
                          buttonColor="highlight"
                          onClick={() => handleEditInteractiveObject(item.bookId, item.interactiveObjectId)}>
                          編書
                        </Button>
                      ) : (
                        <Button
                          buttonColor="highlight"
                          onClick={() => handleCreateInteractiveObjectId(item.bookId)}>
                          建立編輯紀錄
                        </Button>
                      )
                    }
                    <Button
                      buttonColor="normal"
                      onClick={() => handlePreviewBook(item.bookId)}>
                      預覽
                    </Button>
                    <Button
                      buttonColor="operate"
                      onClick={() => handleDocConvert(item.bookId)}>
                      轉檔
                    </Button>
                    <Button
                      buttonColor="delete"
                      onClick={() => handleDeleteBook(item.bookId)}>
                      刪除
                    </Button>
                  </UiBtn>
                </UiTableItem>
              </UiTableContentItem>
            ))
          }
        </UiTableContent>
      </UiTable>
      {
        (searchType === '' || searchType === OPTIONS_TYPE.Name) && (
          <UiPagination>
            <Pagination
              count={Math.ceil(total / 10)}
              page={currentPage}
              onChange={handlePaginationChange}
            />
          </UiPagination>
        )
      }
      <Modal
        isOpen={modalDeleteState.isOpen}
        title={modalDeleteState.title}
        text={modalDeleteState.text}
        buttons={modalDeleteButtons}
        getModalState={getModalDeleteStateHandler}>
        <UiDeleteVerifyWrap>
          <UiTextField variant='outlined' onChange={verifyDeleteInput}></UiTextField>
          {deleteVerifyInput.isError ? <UiVerifyErrorMsg>請輸入正確內容</UiVerifyErrorMsg> : null}
        </UiDeleteVerifyWrap>
      </Modal>
      <Modal
        isOpen={isOpenFormModal}
        title="編輯 JSON"
        buttons={formModalButtons}
      >
        <MatFormControl component="fieldset" fullWidth>
          <UiFieldset>
            <MatFormLabel component="label">Product ID</MatFormLabel>
            <MatTextField
              fullWidth
              disabled={true}
              value={modalForm.productItemId}
            />
          </UiFieldset>
          <UiFieldset>
            <MatFormLabel component="label">互動物件 ID</MatFormLabel>
            <MatTextField
              fullWidth
              disabled={true}
              value={modalForm.interactiveObjectId}
            />
          </UiFieldset>
          <UiFieldset>
            <MatFormLabel component="label">PDF File Name</MatFormLabel>
            <MatTextField
              fullWidth
              disabled={true}
              value={modalForm.GCPFileName}
            />
          </UiFieldset>
          <UiFieldset>
            <MatFormLabel component="label">翻頁模式</MatFormLabel>
            <MatRadioGroup row aria-label="gender" name="flip" value={modalForm.LRFlip} onChange={handleChangeLRFlip}>
              <MatFormControlLabel value={BOOK_FLIP_TYPE.RightToLeft} control={<MatRadio />} label="L" />
              <MatFormControlLabel value={BOOK_FLIP_TYPE.LeftToRight} control={<MatRadio />} label="R" />
            </MatRadioGroup>
          </UiFieldset>
        </MatFormControl>
      </Modal>
      <Modal
        isIconShow={false}
        isOpen={isOpenUploadModal}
        title="上傳PDF"
        content={<PdfUploader getFileHandler={getFileHandler} />}
        getModalState={state => openUploadModal(state)}
        buttons={uploadModalButtons()}
      />
    </UiLibrary>
  );
};

