import styled from 'styled-components';

export const UiLibrary = styled.div`
width: 100%;
`;


export const Uioption = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;
`;

export const UisearchOptions = styled.div`
	margin: 0 10px;
`;

export const UiSearch = styled.div`
	display: flex;
	align-items: center;
	width: 80%;

	& > button {
		margin-left: 10px;
	}
`;

export const UiThumbnail = styled.img`
	width: 90%;
	height: 100%;
`;

export const UiPagination = styled.div`
	display: flex;
	justify-content: center;
	margin: 3rem 0;
`;


export const UiBtn = styled.div`
	display: flex;
	flex-wrap: wrap;

	> button {
		margin: 5px;
		width: calc(50% - 10px);
	}
`;

export const UiFieldset = styled.div`
	padding: 10px 0;
`;

export const UibuttonBox = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	justify-content: flex-end;
`;
