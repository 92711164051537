import { Api } from 'services/api';
import { stringify } from 'query-string';

/**
 * 取得 所有書籍創立時間
 *
 * @param {string} category
 *
 * @returns {object} all docs create date list
 */

export const getTotalDocsCreateDate = async params => {
  const response = await Api.get('/doc/total');
  return response;
};


/**
 * 取得 所有轉檔後台書本資訊
 *
 * @param {string} bookId
 *
 * @returns {object} doc
 */

export const getDocById = async bookId => {
  const response = await Api.get(`/doc/${bookId}`);
  return response;
};


/**
 * 取得 轉檔後台書本列表
 *
 * @param {string} createDate
 *
 * @returns {object}  docs
 */

export const getDocs = async ({ createDateBefore }) => {
  const queryParams = {
    createDateBefore,
    limit: 10
  };
  const querystring = stringify(queryParams, { arrayFormat: 'bracket' });
  const response = await Api.get(`/doc?${querystring}`);
  return response;
};


/**
 * 取得 用轉檔狀態搜尋書本
 *
 * @param {array}} status
 *
 * @returns {object}  docs
 */

export const getDocsByStatus = async status => {
  const queryParams = {
    status
  };
  const querystring = stringify(queryParams, { arrayFormat: 'bracket' });
  const response = await Api.get(`/doc?${querystring}`);
  return response;
};



/**
 * 取得 用檔案名稱搜尋書本
 *
 * @param {array}} status
 *
 * @returns {object}  docs
 */

export const getDocsByName = async ({ name, page }) => {
  const limit = 10;
  const queryParams = {
    name,
    skip: (page - 1) * limit,
    limit
  };
  const querystring = stringify(queryParams, { arrayFormat: 'bracket' });
  const response = await Api.get(`/doc?${querystring}`);
  return response;
};



/**
 * 更新書本 JSON 內容
 *
 * @param {params} { LRFlip }
 *
 * @returns {object}  docs
 */

export const updateDocBook = async ({ bookId, params }) => {
  const response = await Api.put(`/doc/${bookId}`, { ...params });
  return response;
};


/**
 * 轉檔
 *
 * @param {string}} bookId
 *
 */

export const docConvert = async bookId => {
  const response = await Api.post(`/doc/${bookId}/convert`);
  return response;
};


const onebookApiDomain = 'https://us-central1-onebook-dev.cloudfunctions.net';

export const deleteDocBook = async bookId => {
  const token = Api.settings.headers.Authorization || '';

  const deleteOnebookApi = await Api.post(`${onebookApiDomain}/deleteBook`, { bookId, token: { jwt: token } });
  const deleteDocApi = await Api.delete(`/doc/${bookId}`);
  return Promise.all([deleteDocApi, deleteOnebookApi]).then(res => {
    return res[0];
  });
};


export const createInteractiveObject = async bookId => {
  const name = '南一版';
  const token = Api.settings.headers.Authorization || '';
  const response = await Api.post(`${onebookApiDomain}/createInteractiveObject`,
    { bookId, name, token: { jwt: token }, }
  );
  return response;
};

export const getDocBooks = async bookIds => {
  const token = Api.settings.headers.Authorization || '';
  const response = await Api.post(`${onebookApiDomain}/getBooks`, { bookIds, token: { jwt: token } });
  return response;
};

export const uploadPdfFile = async (params, data) => {
  const querystring = await stringify(params);
  const apiUrl = `${process.env.REACT_APP_PDF_API_DOMAIN}/upload_srcfile?${querystring}`;
  const settings = Object.assign({
    body: data ? data : undefined,
    method: 'POST',
    headers: undefined
  });
  const response = await fetch(apiUrl, settings);
  return response.json();
};
