import React from 'react';
import { Switch } from 'react-router-dom';
import RenderRoutes from 'routes/RenderRoutes';
import commonRoutes from 'routes/routesConfig/commonRoutes';


const AppRoutes = () => {
  return (
    <Switch>
      <RenderRoutes routes={commonRoutes} />
    </Switch>
  );
};


export default AppRoutes;
